export const environment = {
  production: true,
  CLIENT_BASE_URL:
  // 'http://localhost:3000/offline/client/api',
  'https://d1aczabmblwch8.cloudfront.net/dev/client/api',
    
    FILE_BASE_URL:
    'https://mango-public-storage-dev.s3.eu-central-1.amazonaws.com',

};
